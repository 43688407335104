import React from "react";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return <div className="footer">© {currentYear} Matias Rivera Zahn</div>;
};

export default Footer;
